import logo from './assets/MomoBrown.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2 className="momo-title">
          MOMO PELUCHE
        </h2>
        <p>Prontamente</p>
      </header>
    </div>
  );
}

export default App;
